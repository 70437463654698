<!-- =========================================================================================
    File Name: VxBreadcrumb.vue
    Description: Breadcrumb component
    Component Name: VxBreadcrumb
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template functional>
    <div class="vx-breadcrumb" :class="data.staticClass">
        <ul v-if="props.store.state.AppActiveUser.userRole === 'manager'" class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
                <router-link :to="`/${props.lang}/warehouse-agent/dashboard`">
                    <feather-icon icon="HomeIcon" svgClasses="h-5 w-5 mb-1 stroke-current text-primary" />
                </router-link>
                <span class="breadcrumb-separator mx-2"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
            </li>
            <li class="inline-flex items-center">
                <router-link v-if="props.route.name === 'manager-packages-in-bin'" class="capitalize" :to="`/${props.lang}/warehouse-agent/warehouses/${props.route.params.warehouseID}/bins`">{{ props.i18nData[props.lang]['Bins'] }}</router-link>
                <router-link v-else class="capitalize" :to="`/${props.lang}/warehouse-agent/warehouses/${props.route.params.warehouseID}/slots`">{{ props.i18nData[props.lang][props.route.params.type] }}</router-link>
                <span class="breadcrumb-separator mx-2 flex items-start"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
                <span>{{ props.i18nData[props.lang][`${props.route.params.type ? 'Slot View' : 'Bin View'}`] }}</span>
            </li>
        </ul>
        <ul v-else-if="props.store.state.AppActiveUser.userRole === 'head-of-fleet'" class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
                <router-link :to="`/${props.lang}/team-leader/warehouses/${props.route.params.warehouseID}/dashboard`">
                    <feather-icon icon="HomeIcon" svgClasses="h-5 w-5 mb-1 stroke-current text-primary" />
                </router-link>
                <span class="breadcrumb-separator mx-2"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
            </li>
            <li class="inline-flex items-center">
                <router-link v-if="props.route.name === 'head-of-fleet-order'" class="capitalize" :to="`/${props.lang}/team-leader/warehouses/${props.route.params.warehouseID}/${props.route.params.page}/${props.route.params.taskListID}`">{{ props.i18nData[props.lang]['Task List View'] }}</router-link>
                <router-link v-else class="capitalize" :to="`/${props.lang}/team-leader/warehouses/${props.route.params.warehouseID}/${props.route.params.page}`">{{ props.i18nData[props.lang][props.route.params.page] }}</router-link>
                <span class="breadcrumb-separator mx-2 flex items-start"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
                <span>{{ props.i18nData[props.lang]['Order View'] }}</span>
            </li>
        </ul>
        <ul v-else-if="props.route.name.includes('-fulfillment-order-view')" class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
                <router-link to="/">
                    <feather-icon icon="HomeIcon" svgClasses="h-5 w-5 mb-1 stroke-current text-primary" />
                </router-link>
                <span class="breadcrumb-separator mx-2"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
            </li>
            <li class="inline-flex items-center">
                <router-link class="capitalize" :to="`/${props.lang}/${props.store.state.AppActiveUser.userRole}/fulfillment/${props.route.params.type}-orders`">{{ props.i18nData[props.lang][props.route.params.type] }}</router-link>
                <span class="breadcrumb-separator mx-2 flex items-start"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
                <span>{{ props.i18nData[props.lang]['Order View'] }}</span>
            </li>
        </ul>
        <ul v-else-if="props.route.params.orderID" class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
                <router-link to="/">
                    <feather-icon icon="HomeIcon" svgClasses="h-5 w-5 mb-1 stroke-current text-primary" />
                </router-link>
                <span class="breadcrumb-separator mx-2"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
            </li>
            <li class="inline-flex items-center">
                <router-link class="capitalize" v-if="props.store.state.AppActiveUser.userRole !== 'finance'" :to="`/${props.lang}/${props.store.state.AppActiveUser.userRole}/orders/${props.route.name.includes('exchanges') ? 'exchanges' : props.route.params.type}`">{{ props.route.name.includes('exchanges') ? props.i18nData[props.lang]['exchanges'] : props.i18nData[props.lang][props.route.params.type] }}</router-link>
                <router-link class="capitalize" v-else :to="`/${props.lang}/accounting/accounts/merchants`">{{ props.i18nData[props.lang]['Merchants'] }}</router-link>
                <span class="breadcrumb-separator mx-2 flex items-start"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
                <span>{{ props.i18nData[props.lang]['Order View'] }}</span>
            </li>
        </ul>
        <ul v-else class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
                <router-link to="/">
                    <feather-icon icon="HomeIcon" svgClasses="h-5 w-5 mb-1 stroke-current text-primary" />
                </router-link>
                <span class="breadcrumb-separator mx-2"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
            </li>
            <li v-for="(link, index) in props.route.meta.breadcrumb.slice(1,-1)" :key="index" class="inline-flex items-center">
                <router-link :to="`/${props.lang}/${link.url}`" v-if="link.url">{{ props.i18nData[props.lang][link.title] }}</router-link>
                <span class="text-primary cursor-default" v-else>{{ props.i18nData[props.lang][link.title] }}</span>
                <span class="breadcrumb-separator mx-2 flex items-start"><feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
            </li>
            <li class="inline-flex">
                <span 
                    v-if="props.route.meta.breadcrumb.slice(-1) && props.route.meta.breadcrumb.slice(-1).length > 0 
                        && props.route.meta.breadcrumb.slice(-1)[0] && props.route.meta.breadcrumb.slice(-1)[0].active" 
                    class="cursor-default">{{ props.i18nData[props.lang][props.route.meta.breadcrumb.slice(-1)[0].title] }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default{
  name: 'vx-breadcrumb'
}
</script>